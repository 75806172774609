/* https://maintainablecss.com/chapters/introduction/ */
.Notifications {
    position: fixed;
    top: 0;
    left: 0;
}

.Notifications
.Notifications-item {
    animation-name: Notifications-fadeout;
    animation-timing-function: ease-out;
}

.Notifications
.Notifications-Snackbar {
    position: static;
    padding: 1em;
}

.Notifications-message {
    flex: 1
}

.Notifications-body.--Loader {
    display: flex;
    width: 11rem;
    justify-content: center;
}

@keyframes Notifications-fadeout {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-1em);
    }
}
