.Filter:after {
    content: '';
    height: 100%;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    background: #999;
    transition: width 200ms;
}

.Filter.--active:after {
    width: .5em;
}

.Filter-header {
    text-align: left;
    flex: 1;
}

.Filter-noGroupName {
    background-color: transparent;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700 !important;
    line-height: 1.6 !important;
    text-align: left;
    padding-left: 16px;
    letter-spacing: 0.0075em !important;
}

.Filter-noGroupName-details {
    padding-left: 48px;
}

.Filter-icon {
    width: 32px;
    height: 32px;
}