.request-modal-container {
  padding: 16px;
  text-align: center;
}

.request-modal-inputs {
  display: flex;
  flex-direction: column;
}

.request-modal-inputs div {
  margin-top: 16px;
}

.request-modal-error {
  margin: 16px;
  padding: 16px;
  background-color: #ff0000;
  color: #ffffff;
}

.request-modal-success {
  margin: 16px;
  padding: 16px;
  background-color: #00637c;
  color: #ffffff;
}

.request-modal-product {
  max-width: 400px;
  margin: 0 auto;
}