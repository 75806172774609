/* https://maintainablecss.com/chapters/introduction/ */
.Product {
    max-width: 35em;
    min-width: 28em;
}

.Product-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.Product-image {
    width: 100%;
    min-height: 200px;
    padding-top: 80%;
    margin: 1em 0;
    text-align: center;
}

.Product-properties {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    margin: 1em 0;
}

.Product-box-shadow {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.Product-properties-expanded {
    padding-bottom: 0 !important;
}

.Product-properties li {
    display: flex;
    justify-content: space-between;
}

.Product-properties hr:last-child {
    display: none;
}

.Product-actions {
    display: flex;
    justify-content: space-between;
}

.Expand-icon-label {
    align-self: center;
    padding-left: 16px;
}

.Product-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Product-row.name-container {
    width: 60%;
    justify-content: flex-start;
}

.Product-row.value-container {
    width: 35%;
    justify-content: flex-end;
    text-align: right;
}

/** for the first two rows (manufacturer and category inverse the width ratio */

.Product-properties li:first-child .Product-row.name-container, .Product-properties li:nth-child(2) .Product-row.name-container {
    width: 35% !important;
}

.Product-properties li:nth-of-type(2) .Product-row.name-container {
    width: 35% !important;
}

.Product-properties li:first-child .Product-row.value-container {
    width: 60% !important;
}

.Product-properties li:nth-of-type(2) .Product-row.value-container {
    width: 60% !important;
}

.Product-properties-expanded li:first-child .Product-row.name-container {
    width: 35% !important;
}

.Product-properties-expanded li:nth-of-type(2) .Product-row.name-container {
    width: 35% !important;
}

.Product-properties-expanded li:first-child .Product-row.value-container {
    width: 60% !important;
}

.Product-properties-expanded li:nth-of-type(2) .Product-row.value-container {
    width: 60% !important;
}

.Product-row.value-container-full-width {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
}

.Product-row.highlight {
    background-color: #00637c;
    color: #FFFFFF;
}

.Product-row.preview {
    background-color: #F0F0F0;
}

.Product-row.title {
    background-color: transparent;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.Product-row.title-container {
    padding-top: 32px;
    justify-content: start;
}

.Product .MuiCardHeader-title {
   min-height: 3em;
}

.Product .Product-collapse-button {
    margin-top: -47%;
    width: 30px;
    min-width: 30px;
    float: right;
}

.Product .Product-displayProductDetailsButtonWrapper {
    height: 0;
}

.Product .Product-displayProductDetailsButton {
    margin-top: -90%;
}

.Product-country-icon {
    height: 30px;
}

.Product-country-icon img {
    width: 30px;
}