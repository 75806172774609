.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
}


/*/  Pages  /*/
.App-page {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}


/*/  Forms  /*/
.App-form {
    display: flex;
    flex-flow: column;
    max-width: 22em;
}
