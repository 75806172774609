/* https://maintainablecss.com/chapters/introduction/ */
.OverviewPage {
    max-height: calc(100vh - 290px);
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    grid-template-areas:
    "header"
    "headerMain"
    "aside"
    "main";
    grid-column-gap: 10px;
    grid-row-gap: 0;
    padding-left: 20px;
}

@media (min-width: 870px) {
    .OverviewPage {
        grid-template-rows: min-content auto;
        grid-template-columns: minmax(22rem, 22%) auto;
        grid-template-areas:
      "header headerMain"
      "aside main";
    }
}

.OverviewPage-header {
    grid-area: header;
    margin-bottom: 10px;
}

.OverviewPage-aside {
    grid-area: aside;
    max-height: calc(100vh - 320px);
    overflow: auto;
    text-align: center;
}

.OverviewPage-aside.loading {
    overflow: hidden;
}

.OverviewPage-buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.OverviewPage-productLength {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    align-items: flex-end;
}

.OverviewPage-buttonContainer-singleCategory {
    max-width: 22%;
    min-width: 22rem;
    text-align: right;
}

.OverviewPage-buttonInnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85px;
    min-width: 16rem;
}

.OverviewPage-headerMain {
    margin-bottom: 10px;
}

@media (max-width: 1850px) {
    .OverviewPage-headerMain {
        margin-left: 55px;
    }
}

.OverviewPage-main {
    grid-area: main;
    max-height: calc(100vh - 320px);
    overflow: auto;
    display: flex;
    align-items: flex-start;
}

.OverviewPage-main.loading {
    overflow: hidden;
}

.OverviewPage-main
.Product {
    flex: 1 0 45%;
    margin: 0 1rem 1rem 0;
}

.NoFiltersLabel {
    font-size: 1rem;
    text-align: left;
}

.NoResultsLabel {
    font-size: 1rem;
}

.OverViewPage-filter-group-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.OverviewPage-filter-group {
    background-color: transparent;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700 !important;
    line-height: 1.6 !important;
    text-align: left;
    padding: 8px 0 8px 10px;
    letter-spacing: 0.0075em !important;
}

.OverviewPage-filter-group:first-child {
    padding-top: 0;
    padding-bottom: 0;
}
